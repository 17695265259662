<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reclamaciones')+': '+$t('Vehículo')}} </h3>
		</div>
		<div class="p-col-12">
			<div class="card">
                <DataTable :value="claims" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-mt-3" scrollDirection="both"
					:paginator="true" :rows="25">
					<template #header>
                        <div class="table-header">
							<div>
								<Dropdown class="p-mr-2" v-model="year" :options="$store.state.years" optionLabel="nombre"></Dropdown>
								<Dropdown class="p-mr-2" v-model="mes" :options="$store.state.meses" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
								<Dropdown class="p-mr-2" v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
								<Button :disabled ="$store.state.loading" :label="$t('Buscar')" style="width: 110px;" icon="pi pi-search" class="p-button-primary p-mr-2" @click="Buscar()"/>
							</div>
							<div>{{ $t('Registros') }}: {{ claims.length }}</div>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
                    <Column field="fecha" :header="$t('Fecha del incidente')" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{convfech(slotProps.data.fecha)}}
                        </template>
                    </Column>
                    <Column field="nombre" :header="$t('Titular de la póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}                              
                        </template>
                    </Column>
                    <Column field="poliz" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.poliz}}                        
                        </template>
                    </Column>
                    <Column field="codigo" :header="$t('Claim')+' #'" :sortable="true" headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                       
                        </template>
                    </Column>
                    <Column field="veh_placa" :header="$t('Placa N°')" :sortable="true" headerStyle="width: 120px">
                        <template #body="slotProps">
                            {{slotProps.data.veh_placa}}
                        </template>
                    </Column>
                    <Column field="benef_nombre" :header="$t('Contraparte')" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.benef_nombre}}
                        </template>
                    </Column>
                    <Column :header="$t('Estatus')" field="estado_name" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
							<strong :style="'font-size: 12px;color:'+slotProps.data.color" class="p-mr-2 p-mb-2" v-if="slotProps.data.estado!=2 && i18n.locale() == 'es'">
								{{slotProps.data.estado_nombre}}
							</strong>
							<strong :style="'font-size: 12px;color:'+slotProps.data.color" class="p-mr-2 p-mb-2" v-if="slotProps.data.estado!=2 && i18n.locale() == 'en'">
								{{slotProps.data.estado_name}}
							</strong>
							<strong :style="'font-size: 12px;color:'+slotProps.data.color" class="p-mr-2 p-mb-2" v-if="slotProps.data.estado==2 && i18n.locale() == 'es'">
								{{slotProps.data.estado2_nombre}}
							</strong>
							<strong :style="'font-size: 12px;color:'+slotProps.data.color" class="p-mr-2 p-mb-2" v-if="slotProps.data.estado==2 && i18n.locale() == 'en'">
								{{slotProps.data.estado2_name}}
							</strong>
							<i class="pi pi-clock p-mr-2 p-mb-2" v-if="slotProps.data.migracion"/>
							<i class="pi pi-bell p-mr-2 p-mb-2" v-if="slotProps.data.edit_claim"/>
                        </template>
                    </Column>   
                    <Column field="estimacion" :header="$t('Monto de Reserva')"  headerStyle="width: 180px">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.estimacion)}}                              
                        </template>
                    </Column>
					<Column field="total" :header="$t('Total')" headerStyle="width: 150px">
                        <template #body="slotProps">
							{{moneda(slotProps.data.total_final)}}
                        </template>
                    </Column>
                    <Column field="total" :header="$t('Pagar')" headerStyle="width: 150px">
                        <template #body="slotProps">
							{{moneda(slotProps.data.to_pay)}}
                        </template>
                    </Column>
                    <Column field="total" :header="$t('Pago')" headerStyle="width: 150px">
                        <template #body="slotProps">
							{{moneda(slotProps.data.benef_total)}}
                        </template>
                    </Column>
                    <Column field="observacion" :header="$t('Comentario')" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.observacion}}
                        </template>
                    </Column>
                    <template #footer footerStyle="text-align:right">
						<div class="p-grid p-align-center vertical-container">
							<div class="p-col-11">
								{{$t('Reserva')}}: {{moneda(reserva)}} <br> 
								{{$t('Total')}}: {{moneda(total)}} <br> 
								{{$t('Pagar')}}: {{moneda(pagar)}} <br> 
								{{$t('Pago')}}: {{moneda(pago)}} <br> 
							</div>
							<div class="p-col-1">
								<Button :disabled ="$store.state.loading" icon="pi pi-download" class="p-button-rounded p-button-warning p-mr-2" @click="VerXLS()" v-if="claims.length>0"/>
							</div>
						</div>
                    </template>
                </DataTable>
			</div>		
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Reclamos','Claim2');
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import url from "@/service/_URL";
	import XLS from "../service/XLS";

	export default {
		data() {
			return {
				year: null,
				mes: null,
				nuevo: false,
				buscar: null,
				opcion: {nombre: 'Todos', name: 'All', code: -1, color: ''},
				opciones: [{nombre: 'Todos', name: 'All', code: -1, color: ''}],
				resultado: [],
				insert: false,
				edit: false,
				del: false,
				poliza: null,
				filters: {},
				selectedCustomers: null,
				submitted: false,
				size: '60vw',
				polizas: [],
				seleccion: {},
				mostrar: false,
				forences: [],
				tipos: [],
				estados: [],
				validationErrors: {},
				estado: null,
				estado2: null,
				claims: [],
				bancos: [],
				pdf: null,
				lpdf: null,
				fecha1: null,
				fecha2: null,
				minimo: null,
				maximo: null,
				
				total: 0,
				reserva: 0,
				pagar: 0,
				pago: 0
			}
		},
		created() {
			this.url = url;
			this.year = this.$store.state.year;
			this.mes = this.$store.state.mes;
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			const tokgen = new Token(256);
			this.pdf = tokgen.generate();
			this.lpdf = tokgen.generate();
			this.fecha1 = Consulta.fstring(this.$store.state.fecha);
			this.fecha2 = Consulta.fstring(this.$store.state.fecha);
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.claims = [];
				this.polizas = [];
				this.mostrar = false;
				Consulta.Procesar('Cons_Main',{
					id: 0,
				}).then(response => {
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.tipos = response.result.tipos;
					this.estados = response.result.estados;
					response.result.opciones.forEach(element => {
						this.opciones.push(element);
					});
				}); 
			},
			Buscar(){
				this.total = 0;
				this.reserva = 0;
				this.claims = [];
				this.total = 0;
				this.reserva = 0;
				this.pagar = 0;
				this.pago = 0;
				if(this.mes && this.year && this.opcion){
					this.mostrar = false;
					this.seleccion = {};
					this.claims = [];
					Consulta.Procesar('BuscarMain',{
						opcion: this.opcion.code,
						mes: this.mes.code,
						year: this.year.code,
						}).then(response => {
							//this.$store.state.error = response;
							this.claims = response.result;
							this.Calcular();
						});
				}
			},
			VerXLS(){
				const xls = new XLS();
				xls.Reporte3(this.claims,this.$store.state.currency,this.reserva,this.pagar,this.pago);
			},
			Calcular(){
				var total = 0; 
				var reserva = 0; 
				var pagar = 0; 
				var pago = 0;
				this.claims.forEach(element => {
					if(element.total_final){total = parseFloat(total) + parseFloat(element.total_final);}
					if(element.estimacion){reserva = parseFloat(reserva) + parseFloat(element.estimacion);}
					if(element.to_pay){pagar = parseFloat(pagar) + parseFloat(element.to_pay);}
					if(element.benef_total){pago = parseFloat(pago) + parseFloat(element.benef_total);}
				});
				this.total = total;
				this.reserva = reserva;
				this.pagar = pagar;
				this.pago = pago;
			},
			moneda(number){
				if(number== null){number=0;}
				return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
